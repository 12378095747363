.ScannerFullScreen {
    position: fixed;
    width: 100%;;
    height: 100%;
    background-color: black;
    top: 0;
    left: 0;
}

.btnControls {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
}