/*button {
    background-color:bisque;
    padding: 10px;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 5px;
    margin-bottom: 3px;
}

button:hover {
    border-color: black;
    border: 1px solid;
}*/

select {
    width: 100%;
    padding: 15px;
    margin-bottom: 3px;
}

/*input[type=text] {
    width: 100%;
    padding: 15px;
    margin-bottom: 3px;
    background-color: #f7f7f7;
    border: 2px solid #ffffff;
    border-radius: 5px;
}*/
/*input[type=password] {
    width: 100%;
    padding: 15px;
    margin-bottom: 3px;
    background-color: #f7f7f7;
    border: 2px solid #ffffff;
    border-radius: 5px;
}*/

input[type=text]:active {
    border: 2px solid rgb(110, 77, 153);
    
}

label {
    font-weight: bold;
    font-size: 12px;
}

/*.btnRed {
    background-color: firebrick;
    color: #f7f7f7;
    padding: 10px;
    border-radius: 3px;
}*/

.logo-home {
    width: 30%;
}