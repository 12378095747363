.thumbnail {
    height: 75px;
    border-radius: 5px;
    margin-right: 10px;
}

.productTitle {
    font-size: 12px;
    font-weight: bold;
}

.imageProductModal {
    height: 250px;
}

.productContainer {
    display: flex;
    background-color: #fff;
    margin-bottom: 8px;
    padding: 5px;
    border-radius: 5px;
}


.productDetails{
    font-size: 10x;
}

